import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconSelectMultipleCustom(props: IconProps) {
	return (
		<Icon viewBox="0 0 61 60" {...props}>
			<path
				fill="#E6F2F3"
				d="M17.8 15.5v19c0 2.8 0 4.2.6 5.3a5 5 0 0 0 2.2 2.2c1 .5 2.4.5 5.2.5h19c2.8 0 4.2 0 5.3-.5a5 5 0 0 0 2.2-2.2c.5-1.1.5-2.5.5-5.3v-19c0-2.8 0-4.2-.5-5.3A5 5 0 0 0 50 8c-1-.5-2.5-.5-5.3-.5h-19c-2.8 0-4.2 0-5.2.5a5 5 0 0 0-2.2 2.2c-.6 1.1-.6 2.5-.6 5.3Z"
			/>
			<path
				stroke="#027F88"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M7.8 22.5v26c0 1.4 0 2.1.3 2.6.2.5.6.9 1.1 1.1.5.3 1.2.3 2.6.3h26m5-32.5-10 10-5-5m-10 9.5v-19c0-2.8 0-4.2.6-5.3A5 5 0 0 1 20.6 8c1-.5 2.4-.5 5.2-.5h19c2.8 0 4.2 0 5.3.5a5 5 0 0 1 2.2 2.2c.5 1.1.5 2.5.5 5.3v19c0 2.8 0 4.2-.5 5.3A5 5 0 0 1 50 42c-1 .5-2.5.5-5.3.5h-19c-2.8 0-4.2 0-5.2-.5a5 5 0 0 1-2.2-2.2c-.6-1.1-.6-2.5-.6-5.3Z"
			/>
		</Icon>
	);
}
